@tailwind base;
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

@layer base {
  h1 {
    @apply text-3xl;
    @apply font-extrabold;
  }
  h2 {
    @apply text-2xl;
    @apply font-bold;
  }
  h3 {
    @apply text-xl;
    @apply font-semibold;
  }
  h4 {
    @apply text-lg;
    @apply font-medium;
  }
}

@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Rubik,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes custom-pulse {
  0% {
    background-position:5% 30%
  }
  50% {
    background-position:95% 100%
  }
  100% {
    background-position:5% 30%
  }
}

.animate-custom-gradient {
  background: linear-gradient(130deg, #c9a7f0 ,#f3e8ff, #daf1ff,#99cdf0);
  background-size: 400% 400%;
  animation: custom-pulse ease-in-out 5s infinite
}

.dot-spin {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -36px 0 0 #8e44ad, 25.4px -25.4px 0 0 #8e44ad, 36px 0 0 0 #8e44ad, 25.4px 25.4px 0 0 rgba(152, 128, 255, 0), 0 36px 0 0 rgba(152, 128, 255, 0), -25.4px 25.4px 0 0 rgba(152, 128, 255, 0), -36px 0 0 0 rgba(152, 128, 255, 0), -25.4px -25.4px 0 0 rgba(152, 128, 255, 0);
  animation: dot-spin 1.75s infinite linear;
}

@keyframes dot-spin {
  0%, 100% {
    box-shadow: 0 -36px 0 0 #8e44ad, 25.4px -25.4px 0 0 #8e44ad, 36px 0 0 0 #8e44ad, 25.4px 25.4px 0 -5px rgba(152, 128, 255, 0), 0 36px 0 -10px rgba(152, 128, 255, 0), -25.4px 25.4px 0 -10px rgba(152, 128, 255, 0), -36px 0 0 -10px rgba(152, 128, 255, 0), -25.4px -25.4px 0 -10px rgba(152, 128, 255, 0);
  }
  12.5% {
    box-shadow: 0 -36px 0 -10px rgba(152, 128, 255, 0), 25.4px -25.4px 0 0 #8e44ad, 36px 0 0 0 #8e44ad, 25.4px 25.4px 0 0 #8e44ad, 0 36px 0 -10px rgba(152, 128, 255, 0), -25.4px 25.4px 0 -10px rgba(152, 128, 255, 0), -36px 0 0 -10px rgba(152, 128, 255, 0), -25.4px -25.4px 0 -10px rgba(152, 128, 255, 0);
  }
  25% {
    box-shadow: 0 -36px 0 -10px rgba(152, 128, 255, 0), 25.4px -25.4px 0 -10px rgba(152, 128, 255, 0), 36px 0 0 0 #8e44ad, 25.4px 25.4px 0 0 #8e44ad, 0 36px 0 0 #8e44ad, -25.4px 25.4px 0 -10px rgba(152, 128, 255, 0), -36px 0 0 -10px rgba(152, 128, 255, 0), -25.4px -25.4px 0 -10px rgba(152, 128, 255, 0);
  }
  37.5% {
    box-shadow: 0 -36px 0 -10px rgba(152, 128, 255, 0), 25.4px -25.4px 0 -10px rgba(152, 128, 255, 0), 36px 0 0 -10px rgba(152, 128, 255, 0), 25.4px 25.4px 0 0 #8e44ad, 0 36px 0 0 #8e44ad, -25.4px 25.4px 0 0 #8e44ad, -36px 0 0 -10px rgba(152, 128, 255, 0), -25.4px -25.4px 0 -10px rgba(152, 128, 255, 0);
  }
  50% {
    box-shadow: 0 -36px 0 -10px rgba(152, 128, 255, 0), 25.4px -25.4px 0 -10px rgba(152, 128, 255, 0), 36px 0 0 -10px rgba(152, 128, 255, 0), 25.4px 25.4px 0 -10px rgba(152, 128, 255, 0), 0 36px 0 0 #8e44ad, -25.4px 25.4px 0 0 #8e44ad, -36px 0 0 0 #8e44ad, -25.4px -25.4px 0 -10px rgba(152, 128, 255, 0);
  }
  62.5% {
    box-shadow: 0 -36px 0 -10px rgba(152, 128, 255, 0), 25.4px -25.4px 0 -10px rgba(152, 128, 255, 0), 36px 0 0 -10px rgba(152, 128, 255, 0), 25.4px 25.4px 0 -10px rgba(152, 128, 255, 0), 0 36px 0 -10px rgba(152, 128, 255, 0), -25.4px 25.4px 0 0 #8e44ad, -36px 0 0 0 #8e44ad, -25.4px -25.4px 0 0 #8e44ad;
  }
  75% {
    box-shadow: 0 -36px 0 0 #8e44ad, 25.4px -25.4px 0 -10px rgba(152, 128, 255, 0), 36px 0 0 -10px rgba(152, 128, 255, 0), 25.4px 25.4px 0 -10px rgba(152, 128, 255, 0), 0 36px 0 -10px rgba(152, 128, 255, 0), -25.4px 25.4px 0 -10px rgba(152, 128, 255, 0), -36px 0 0 0 #8e44ad, -25.4px -25.4px 0 0 #8e44ad;
  }
  87.5% {
    box-shadow: 0 -36px 0 0 #8e44ad, 25.4px -25.4px 0 0 #8e44ad, 36px 0 0 -10px rgba(152, 128, 255, 0), 25.4px 25.4px 0 -10px rgba(152, 128, 255, 0), 0 36px 0 -10px rgba(152, 128, 255, 0), -25.4px 25.4px 0 -10px rgba(152, 128, 255, 0), -36px 0 0 -10px rgba(152, 128, 255, 0), -25.4px -25.4px 0 0 #8e44ad;
  }
}

.custom-test {
  background-color: #8e44ad;
}